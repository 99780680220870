// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-food-beverages-js": () => import("./../../../src/pages/food-beverages.js" /* webpackChunkName: "component---src-pages-food-beverages-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-pricing-js": () => import("./../../../src/pages/info-pricing.js" /* webpackChunkName: "component---src-pages-info-pricing-js" */),
  "component---src-pages-nearby-js": () => import("./../../../src/pages/nearby.js" /* webpackChunkName: "component---src-pages-nearby-js" */),
  "component---src-pages-rent-js": () => import("./../../../src/pages/rent.js" /* webpackChunkName: "component---src-pages-rent-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-thanks-contact-js": () => import("./../../../src/pages/thanks-contact.js" /* webpackChunkName: "component---src-pages-thanks-contact-js" */),
  "component---src-pages-thanks-rent-js": () => import("./../../../src/pages/thanks-rent.js" /* webpackChunkName: "component---src-pages-thanks-rent-js" */),
  "component---src-pages-things-to-do-js": () => import("./../../../src/pages/things-to-do.js" /* webpackChunkName: "component---src-pages-things-to-do-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-poi-js": () => import("./../../../src/templates/poi.js" /* webpackChunkName: "component---src-templates-poi-js" */)
}

